import React, { useState, useEffect } from 'react';
import styles from './ToggleDatePicker.module.scss';
import { DayPickerStrings } from '../../i18n/datepicker.de';

import { DatePicker, IconButton, DayOfWeek } from 'office-ui-fabric-react';
import format from 'date-fns/format';

export interface Props {
	value?: Date;
	readOnly?: boolean;
	onSave: (value: Date) => void;
}

export const ToggleDatePicker = (props: Props) => {
	const [value, setValue] = useState(props.value);
	const [isEditMode, setIsEditMode] = useState(false);

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		if(props.readOnly) {
			setIsEditMode(false);
		}
	}, [props.readOnly]);

	const onFormatDate = (date: Date): string => {
		return format(date, 'dd.MM.yyyy');
	};

	return (
		<div className={[styles.toggleDatePicker, isEditMode ? styles.editMode : ''].join(' ')}>
			{isEditMode ? (
				<DatePicker
					strings={DayPickerStrings}
					firstDayOfWeek={DayOfWeek.Monday}
					value={value}
					onSelectDate={(val) => {
						setValue(val);
					}}
					showGoToToday={false}
					onKeyUp={(evt) => {
						if (evt.key === 'Escape') {
							setValue(props.value);
							setIsEditMode(false);
						}
					}}
					formatDate={onFormatDate}
				/>
			) : (
				<span>{value ? format(value, 'dd.MM.yyyy') : 'ohne Datum'}</span>
			)}
			{isEditMode ? (
				<IconButton
					iconProps={{
						iconName: 'StatusCircleCheckmark',
					}}
					onClick={() => {
						props.onSave(value);
						setIsEditMode(false);
					}}
				/>
			) : (
				<>
					{!props.readOnly ? <IconButton iconProps={{ iconName: 'Edit' }} onClick={() => setIsEditMode(true)} /> : ''}
				</>
			)}
		</div>
	);
};
