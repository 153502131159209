import React from "react";
import {QuestionFormToggle} from "./QuestionFormToggle";

export interface Props {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	onChanged: (checked:boolean)=>void;
}

export const MultipleAnswerOptionsToggle = (props: Props) => {

	return (
		<QuestionFormToggle
			checkedLabelText="Mehrfachauswahl"
			checkedLabelIconName="MultiSelect"
			uncheckedLabelText="Einfachauswahl"
			uncheckedLabelIconName="CheckMark"
			checked={props.checked}
			disabled={props.disabled}
			visible={props.visible}
			onChanged={props.onChanged}
		/>
	);
};