import { validate } from 'email-validator';
import delay from 'lodash/delay';
import { useState } from 'react';
import { IMemberValidationMessage, IMinimalMember } from './store/business/interfaces';

export const useScrollToAriaInvalid = () => {
	const callBack = () => {
		delay(() => {
			const eEnvalid = document.querySelector('[aria-invalid="true"]');
			if (eEnvalid) {
				eEnvalid.scrollIntoView({
					block: 'end',
					inline: 'nearest',
					behavior: 'smooth',
				});
			}
		}, 250);
	};
	return [callBack];
};

export const useMemberValidations = (): [IMemberValidationMessage[], (data: IMinimalMember[]) => void] => {
	const [innerValidations, setValidations] = useState([]);

	const getDuplicates = (members: IMinimalMember[], propertyName: string): IMinimalMember[] => {
		const dups = members
			.filter((item) => {
				return !!item[propertyName];
			})
			.filter((item, index, self) => {
				return self.findIndex((t) => t[propertyName] === item[propertyName]) !== index;
			});
		return dups;
	};

	const getMailValidations = (member: IMinimalMember, dups: IMinimalMember[]): IMemberValidationMessage[] => {
		let mailErrorMessages = [];
		if (member?.eMail) {
			const isValidEMail = validate(member?.eMail);
			if (!isValidEMail) {
				mailErrorMessages.push({ member, field: 'eMail', severity: 'ERROR', text: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' });
			}

			if (dups.find((m) => m.eMail === member?.eMail)) {
				mailErrorMessages.push({ member, field: 'eMail', severity: 'WARN', text: 'Diese E-Mail-Adresse existiert bereits.' });
			}
		}
		return mailErrorMessages;
	};

	const getNameValidations = (member: IMinimalMember, dups: IMinimalMember[]): IMemberValidationMessage[] => {
		let nameErrorMessages = [];
		if(member?.title) {
			if (dups.find((m) => m.title === member?.title)) {
				nameErrorMessages.push({ member, field: 'title', severity: 'WARN', text: 'Dieser Name existiert bereits.' });
			}
		}
		else {
			nameErrorMessages.push({ member, field: 'title', severity: 'ERROR', text: 'Bitte geben Sie einen Namen ein.' });
		}
		return nameErrorMessages;
	};

	const setEntities = (data: IMinimalMember[]) => {
		const duplicateMembersByEmails = getDuplicates(data, 'eMail');
		const duplicateMembersByTitles = getDuplicates(data, 'title');

		let val = new Array<IMemberValidationMessage>();
		data.forEach(member => {
			val.push(...getMailValidations(member, duplicateMembersByEmails));
			val.push(...getNameValidations(member, duplicateMembersByTitles));
		});
		val.sort((v1, v2) => { return v1.severity.localeCompare(v2.severity); });

		setValidations(val);	
	};

	return [innerValidations, setEntities];
};