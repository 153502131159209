import { DefaultButton, Dialog, DialogType, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';
import styles from './ConfirmationDialog.module.scss';

export interface Props {
	hidden: boolean;
	title: string;
	message: string;
	hideDismiss?: boolean;
	onDismiss: () => void;
	onConfirm: () => void;
}

export const ConfirmationDialog = (props: Props) => {
	return (
		<>
			{!props.hidden && (
				<Dialog
					hidden={props.hidden}
					onDismiss={props.onDismiss}
					dialogContentProps={{
						type: DialogType.normal,
						title: props.title,
					}}
					modalProps={{className: styles.dialog}}
				>
					<p className="ms-Dialog-subText" dangerouslySetInnerHTML={{__html: props.message}}></p>
					{!props.hideDismiss && (<DefaultButton onClick={props.onDismiss} text='Abbrechen' />)}
					<PrimaryButton onClick={props.onConfirm} text='Ok' />
				</Dialog>
			)}
		</>
	);
};

