import { ITextFieldProps, TextField } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './AutomaticMultilineTextField.module.scss';

export const AutomaticMultilineTextField = (props: ITextFieldProps) => {
	const [multiline, setMultiline] = useState(false);
	const refRoot = useRef<HTMLDivElement>();

	const calcMultiline = useCallback((newText: string) => {
		const input = refRoot.current.querySelector('input[type="text"],textarea') as
			| HTMLInputElement
			| HTMLTextAreaElement;
		const textWidth = newText.length * 7.5;
		if (input) {
			setMultiline((value) => {
				return textWidth > input.clientWidth;
			});
		}
	}, []);

	useEffect(() => {
		calcMultiline(props.defaultValue);
	}, [props.defaultValue, calcMultiline]);

	return (
		<div ref={refRoot}>
			<TextField
				{...props}
				onChange={(evt, val) => {
					calcMultiline(val);
					props.onChange(evt, val);
				}}
				multiline={multiline}
				autoAdjustHeight={multiline}
				className={styles.automaticMultilineTextField}
			/>
		</div>
	);
};
