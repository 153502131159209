import i18n from 'i18next';

export enum MessageType {
	INFO = 0,
	WARN = 100,
	ERROR = 200,
	SUCCESS = 300
}

export interface IMessage {
	created: Date;
	type: MessageType;
	message?: string;
	sticky?: boolean;
}

function t(text: string) {
	if (i18n.exists(text)) {
		return i18n.t(text);
	}
	return text;
}

interface INotificationConfiguration {
	add: (type: MessageType, msg: string, sticky: boolean) => void;
	clear: () => void;
}

const config: INotificationConfiguration = {
	add: () => {},
	clear: () => {}
};

function startNotification(cfg: INotificationConfiguration) {
	config.add = cfg.add;
	config.clear = cfg.clear;
}

async function showMessage(text: string, type: MessageType = MessageType.INFO, sticky: boolean = false) {
	config.add(type, t(text), sticky);
	return Promise.resolve();
}

async function showError(error: any, sticky: boolean = false) {
	if (error) {
		const message: IMessage = { created: new Date(), type: MessageType.ERROR };
		if (typeof error === 'string') {
			try {
				const err = JSON.parse(error);
				const oError = err['odata.error'];
				if (oError && oError.message && oError.message.value) {
					message.message = oError.message.value;
				}
			} catch (ex) {
				message.message = error;
			}
		} else if (error.response && error.response.data) {
			if (error.response.data instanceof ArrayBuffer && window.TextDecoder) {
				const decoder = new TextDecoder();
				message.message = decoder.decode(error.response.data);
			} else {
				message.message = error.response.data.toString();
			}
		} else if (error.message) {
			message.message = error.message;
		}

		config.add(message.type, t(message.message), sticky);
	}
	return Promise.resolve();
}

async function clearMessages() {
	config.clear();
	return Promise.resolve();
}

export { startNotification, showMessage, showError, clearMessages };
