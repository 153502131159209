import React from 'react';
import { IValidationMessage } from 'src/store/business/interfaces';
import styles from './ErrorIconWithCallout.module.scss';
import { IconWithCallout } from './IconWithCallout';

export interface Props {
	validationMessage?: IValidationMessage;
}

export const ErrorIconWithCallout = (props: Props) => {

	return (
		props.validationMessage ? (
			<IconWithCallout
				iconName='Warning'
				iconContainerClassName={[styles.field, props.validationMessage.severity === 'ERROR' ? styles.error : styles.warn].join(' ')}
				renderCalloutContent={() => <span>{props.validationMessage.text}</span>}
			/>
		) : <></>
	);
};
