import React, { useState, useEffect } from 'react';
import styles from './ResultRatingDetailsPanel.module.scss';
import { Persona, PersonaSize } from 'office-ui-fabric-react';
import { IQuestion, IAnswerOption } from '../../store/business/interfaces';
import { formatFloat } from '../../shared/utils';
import { ExpandablePanel } from './ExpandablePanel';

type PartialAnswerOption = Pick<IAnswerOption, 'attendees' | 'title' | 'averageRating'>;

export interface Props {
	answerOption: PartialAnswerOption;
	question: IQuestion;
	onDismiss: () => void;
}

const renderAttendance = (question: IQuestion, answerOption: PartialAnswerOption, rating: number) => {
	const attendeesForRating = answerOption.attendees.filter((a) => a.rating === rating);
	const attendeeIdsForRating = attendeesForRating.map((a) => a.id);
	const attendees = question.attendees.filter((a) => attendeeIdsForRating.includes(a.id));
	return attendees.map((a) => {
		const voteWeight = attendeesForRating
			.filter((a4vv) => a4vv.id === a.id)
			.reduce((acc, a4vv) => acc + a4vv.voteWeight, 0);
		return (
			<li key={`a4vv-${a.id}-${rating}`} className={styles.memberItem}>
				<Persona
					text={a.text}
					secondaryText={voteWeight > 1 ? `Hat mit ${formatFloat(voteWeight)} Stimmen gewählt` : undefined}
					size={PersonaSize.size48}
				/>
			</li>
		);
	});
};

export const ResultRatingDetailsPanel = (props: Props) => {
	const [details, setDetails] = useState<PartialAnswerOption | undefined>();

	useEffect(() => {
		setDetails(props.answerOption);
	}, [props.answerOption]);

	if (!details) {
		return <></>;
	}

	const stars = new Array(props.question.maxRating ?? 0)
		.fill(0)
		.map((_, i) => i + 1)
		.filter((rating) => details.attendees.some((a) => a.rating === rating))
		.reverse();

	const subTitle = (
		<div className={styles.subTitle}>
			<span className={styles.averageResult}>{Number((details.averageRating || 0).toFixed(1))}</span>
			<span title={details.title} className={styles.answerTitle}>
				{details.title}
			</span>
		</div>
	);

	return (
		<ExpandablePanel isOpen={true} onDismiss={props.onDismiss} title='Beteiligung' subTitle={subTitle}>
			{details && (
				<div className={styles.result}>
					{stars.map((rating) => {
						return (
							<div key={`rating-${rating}`}>
								<h6>{`${rating} Sterne`}</h6>
								<ul className={styles.memberList}>{renderAttendance(props.question, details, rating)}</ul>
							</div>
						);
					})}
				</div>
			)}
		</ExpandablePanel>
	);
};
