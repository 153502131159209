import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './ResultPersonGraph.module.scss';
import { useTranslation } from 'react-i18next';
import clone from 'lodash/clone';
import keys from 'lodash/keys';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import { CommandButton } from 'office-ui-fabric-react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const getBarStyle = (votesCount: number, max: number): React.CSSProperties => {
	return { width: `calc(${(votesCount * 100) / max}% - ${(votesCount * 100) / max}px)` };
};
interface ResultPersonGraphBarProps {
	answerOption: { key: ResultKey; label: string; className: string };
	max: number;
	votes: number;
	useVoteWeightInPercent: boolean;
}
const ResultPersonGraphBar = (props: ResultPersonGraphBarProps) => {
	const barRef = useRef(null);
	const { t } = useTranslation();
	return (
		<TransitionGroup enter appear>
			<CSSTransition
				nodeRef={barRef}
				classNames={{
					appear: styles.itemAppear,
				}}
				timeout={100}
			>
				<div ref={barRef} className={styles.graph}>
					<div className={styles.label}>{t(`voting.person.${props.answerOption.label}`)}</div>
					<div className={[styles.option, props.answerOption.className].join(' ')}>
						<span
							className={[styles.bar, props.votes === 0 ? styles.empty : ''].join(' ')}
							style={getBarStyle(props.votes, props.max)}
						></span>
						<span>{props.votes}{props.useVoteWeightInPercent?'%':''}</span>
					</div>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
};

export interface Props {
	answerOptions: IAnswerOptionResult[];
	sortBy?: 'title' | 'votes';
	detailsIconName?: string;
	showDetails?: boolean;
	useVoteWeightInPercent?: boolean;
	onClickDetails?: (answerOption: IAnswerOptionResult) => void;
}

export interface IAnswerOptionResult {
	id: string;
	title: string;
	voteValues?: { [key: string]: number };
}

type ResultKey = 'yes' | 'no' | 'abstent';

const answerOptionsMap: { key: ResultKey; label: string; className: string }[] = [
	{
		key: 'yes',
		label: 'yes',
		className: styles.yes,
	},
	{
		key: 'no',
		label: 'no',
		className: styles.no,
	},
	{
		key: 'abstent',
		label: 'abstent',
		className: styles.abstent,
	},
];

export const ResultPersonGraph = (props: Props) => {
	const [result, setResult] = useState<IAnswerOptionResult[]>([]);
	const [max, setMax] = useState(0);

	useEffect(() => {
	if (!props.answerOptions) {
			return;
		}
		const clonedResult: IAnswerOptionResult[] = clone(props.answerOptions);
		const voteValues = uniq(flatMap(clonedResult.map((r) => keys(r.voteValues))));
		let localMax = 0;
		clonedResult.forEach((r) => {
			localMax = Math.max(localMax, ...voteValues.map((v) => r.voteValues[v] ?? 0));
		});
		if (props.sortBy === 'title') {
			clonedResult.sort((a, b) => a.title.localeCompare(b.title));
		} else {
			clonedResult.sort((a, b) => {
				let sortResult = (b.voteValues.yes ?? 0) - (a.voteValues.yes ?? 0);
				if (sortResult === 0) {
					sortResult = (a.voteValues.no ?? 0) - (b.voteValues.no ?? 0);
					if (sortResult === 0) {
						sortResult = (b.voteValues.abstent ?? 0) - (a.voteValues.abstent ?? 0);
						if (sortResult === 0) {
							sortResult = a.title.localeCompare(b.title);
						}
					}
				}
				return sortResult;
			});
		}

		setResult(clonedResult);
		setMax(localMax);
	}, [props.answerOptions, props.sortBy]);

	const renderDetailsButton = useCallback(
		(option: IAnswerOptionResult) => {
			if (!(props.onClickDetails && props.showDetails)) return <></>;
			return (
				<CommandButton
					title='Beteiligung anzeigen'
					className={styles.detailsButton}
					iconProps={{ iconName: props.detailsIconName ?? 'ReceiptCheck' }}
					onClick={() => props.onClickDetails(option)}
				/>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.detailsIconName, props.onClickDetails]
	);

	return (
		<div data-testid="resultPersonGraph" className={styles.resultPersonGraph}>
			<ul className={styles.list}>
				{result.map((option, i) => {
					return (
						<li key={i}>
							<h6>
								<span>{option.title}</span>
								{renderDetailsButton(option)}
							</h6>
							<div className={styles.graphs}>
								{answerOptionsMap.map((answerOption) => {
									const votes = option.voteValues[answerOption.key] ?? 0;
									return (
										<ResultPersonGraphBar key={answerOption.key} max={max} votes={votes} answerOption={answerOption} useVoteWeightInPercent={props.useVoteWeightInPercent}/>
									);
								})}
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
