import * as microsoftTeams from '@microsoft/teams-js';

export class TokenService {
	// Details for MSAL Login in Teams in https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa
	private static instance: TokenService;

	public static getInstance(): TokenService {
		if (!TokenService.instance) {
			TokenService.instance = new TokenService();
		}
		return TokenService.instance;
	}

	public async getAccessToken(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
				successCallback: (token) => {
					resolve(token);
				},
				failureCallback: (error) => {
					reject(error);
				}
			};
			microsoftTeams.authentication.getAuthToken(authTokenRequest);
		});
	}
}
