import React from 'react';
import styles from './TeamsSettings.module.scss';
import logo from '../../images/LinkedIn-Banner.jpg';

export const TeamsSettings = () => {
	return (
		<div className={styles.welcomeToVotr}>
			<img src={logo} alt='VOTR' />
		</div>
	);
};
