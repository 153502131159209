import React from 'react';
import { SurveyCreationForm } from '../molecules/SurveyCreationForm';
import { SurveyService } from '../../services/SurveyService';

export interface Props {
	onClose: (surveyId: string | undefined) => void;
}

export const SurveyEditor = (props: Props) => {
	return (
		<SurveyCreationForm
			onCancel={() => {
				props.onClose(undefined);
			}}
			onSave={(surveyInfo) => {
				SurveyService.createSurvey(surveyInfo).then((survey) => {
					props.onClose(survey?.id);
				});
			}}
		/>
	);
};
