import { Icon } from 'office-ui-fabric-react';
import React from 'react';
import { IMember } from '../../store/business/interfaces';
import styles from './OnlineStatus.module.scss';


export interface Props {
	member: IMember;
}

export const OnlineStatus = (props: Props) => {

	return (
		<div className={styles.statusContainer}>
			{props.member.lastLogin ? 
                <Icon className={styles.statusOnline} iconName={'CompletedSolid'} title="Anmeldung erfolgreich" />
                :
                <Icon iconName={'ErrorBadge'} title="Anmeldung noch nicht erfolgt" />
            }
		</div>
	);
};
