// Tutorial: https://www.valentinog.com/blog/redux/
// Redux and TS: https://redux.js.org/recipes/usage-with-typescript
// Guide: https://github.com/piotrwitek/react-redux-typescript-guide

import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import businessReducer from './business/businessReducer';
import systemReducer from './system/systemReducer';
import { emptyMiddleware } from './middleware';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({ business: businessReducer, system: systemReducer });

const storeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppState = ReturnType<typeof rootReducer>;

export const store : any = createStore(rootReducer, storeEnhancers(applyMiddleware(emptyMiddleware(), thunk)));