import { BusinessDataType } from '../store/business/enums';
import { store } from '../store';
import {
	surveyLoaded,
	businessLoading,
	questionsLoaded,
	startStopQuestion,
	surveysLoaded,
} from '../store/business/actions';
import { ISurvey, IQuestion, ISurveyBase, ISurveyCreationInfo } from '../store/business/interfaces';
import { xhr, download, IResponse } from '../xhr';
import { QuestionService } from './QuestionService';
import format from 'date-fns/format';
import { isGuid } from '../shared/utils';
import { SystemLoading } from '../store/system/enums';
import { errorSet, systemLoading } from '../store/system/actions';

export class SurveyService {
	private static emptySurvey(): ISurvey {
		return {
			code: '',
			groupId: '',
			id: '',
			title: '',
			url: '#',
			numberOfMembers: 0,
			useVoteWeight: false,
			useVoteWeightInPercent: false,
			useQuickVotes: false,
			disableResultsInMemberView: false,
			date: undefined,
			created: new Date(),
			status: 'unknown',
			readOnly: true
		};
	}

	private static parseSurveyBase(data: any): ISurveyBase {
		return {
			code: data['code'],
			id: data['id'],
			title: data['title'],
			date: data['date'] ? new Date(data['date']) : undefined,
			created: new Date(data['created']),
			status: data['status'] || 'unknown',
			readOnly: data['status'] !== 'live',
		};
	}

	public static parseSurvey(data: any): ISurvey {
		const survey = SurveyService.parseSurveyBase(data) as ISurvey;
		survey.groupId = data['groupId'];
		survey.url = data['url'];
		survey.useVoteWeight = data['useVoteWeight'] || false;
		survey.useVoteWeightInPercent = data['useVoteWeightInPercent'] || false;
		survey.useQuickVotes = data['useQuickVotes'] || false;
		survey.numberOfMembers = parseInt(data['numberOfMembers'] || 0);
		survey.disableResultsInMemberView = data['disableResultsInMemberView'] || false;
		return survey;
	}

	public static async createSurvey(surveyInfo: ISurveyCreationInfo): Promise<ISurvey | undefined> {
		const { system, business } = store.getState();
		if (business.loading[BusinessDataType.Survey] || !system.context) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));
		const currentSurvey = business.survey;
		try {
			const response = await xhr(`Survey/Create`, {
				method: 'POST',
				data: {
					title: surveyInfo.title,
					date: format(surveyInfo.date, 'yyyy-MM-dd'),
					url: system.context.teamSiteUrl,
					groupId: system.context.groupId,
					copyMembersFromSurvey: surveyInfo.copyMembersFromCurrentSurvey ? currentSurvey?.id : null,
					copyQuestionsFromSurvey: surveyInfo.copyQuestionsFromCurrentSurvey ? currentSurvey?.id : null,
					useQuickVotes: surveyInfo.useQuickVotes,
					disableResultsInMemberView: false,
				},
			});
			const survey = SurveyService.parseSurvey(response.data);
			store.dispatch(surveyLoaded(survey));
			store.dispatch(questionsLoaded(QuestionService.parseArray(response.data['questions'])));
			await SurveyService.loadSurveys();
			return survey;
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
		}
	}

	public static async updateSurvey(data: { id: string; title?: string; date?: Date, useVoteWeight?:boolean, useVoteWeightInPercent?:boolean, useQuickVotes?:boolean, disableResultsInMemberView?:boolean }) {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Survey]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));
		try {
			const response = await xhr(`Survey/${data.id}`, {
				method: 'PATCH',
				data: {
					title: data.title,
					date: data.date ? format(data.date, 'yyyy-MM-dd') : undefined,
					useVoteWeight: data.useVoteWeight != null ? data.useVoteWeight : undefined,
					useVoteWeightInPercent: data.useVoteWeightInPercent != null ? data.useVoteWeightInPercent : undefined,
					useQuickVotes: data.useQuickVotes != null ? data.useQuickVotes : undefined,
					disableResultsInMemberView: data.disableResultsInMemberView != null ? data.disableResultsInMemberView : undefined,
				},
			});
			store.dispatch(surveyLoaded(SurveyService.parseSurvey(response.data)));
			store.dispatch(questionsLoaded(QuestionService.parseArray(response.data['questions'])));
			await SurveyService.loadSurveys();
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
		}
	}

	public static async loadSurvey(id?: string, force?: boolean): Promise<ISurvey | undefined> {
		const { system, business } = store.getState();
		if(!force) {
			if ((id && business.survey?.id === id) || business.loading[BusinessDataType.Survey] || !system.context) {
				return business.survey;
			}
			if (id && !isGuid(id)) {
				return business.survey;
			}
		}
		if (!business.survey) {
			store.dispatch(surveyLoaded(SurveyService.emptySurvey()));
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));
		try {
			let response: IResponse;
			if (id) {
				response = await xhr(`Survey/${id}`, {
					method: 'GET',
					onError: (er) => {
						return Promise.reject();
					},
				});
			} else {
				store.dispatch(systemLoading(SystemLoading.InitialData, true));
				response = await xhr(`Survey/Init`, {
					method: 'POST',
					data: {
						title: system.context.teamName,
						url: system.context.teamSiteUrl,
						groupId: system.context.groupId,
					},
					onError: (er) => {
						store.dispatch(errorSet(er.response?.data || er.toString(), er.response?.status));
						return Promise.reject();
					},
				});
			}
			const survey = SurveyService.parseSurvey(response.data);
			store.dispatch(surveyLoaded(survey));
			store.dispatch(questionsLoaded(QuestionService.parseArray(response.data['questions'])));
			return survey;
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
			store.dispatch(systemLoading(SystemLoading.InitialData, false));
		}
	}

	public static async archiveSurvey(survey: ISurvey) {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Survey]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Archive`, {
				method: 'POST',
			});
			const updatedSurvey = SurveyService.parseSurvey(response.data);
			store.dispatch(surveyLoaded(updatedSurvey));
			store.dispatch(questionsLoaded(QuestionService.parseArray(response.data['questions'])));

			const surveys = business.surveys.filter((s) => s.id !== updatedSurvey.id).concat([updatedSurvey]);
			store.dispatch(surveysLoaded(surveys));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
		}
	}

	public static async deleteSurvey(survey: ISurvey) {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Survey]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));
		try {
			await xhr(`Survey/${survey.id}`, {
				method: 'DELETE',
			});

			const surveys = business.surveys.filter((s) => s.id !== survey.id);
			store.dispatch(surveysLoaded(surveys));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
		}
	}

	public static async loadSurveys(): Promise<ISurveyBase[]> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Surveys]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Surveys, true));
		try {
			const response = await xhr(`Survey/List`, {
				method: 'GET',
			});
			const surveys = (response.data as any[]).map(SurveyService.parseSurveyBase);
			store.dispatch(surveysLoaded(surveys));
			return surveys;
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Surveys, false));
		}
	}

	public static async startStopQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question/${question.id}/StartStop`, {
				method: 'GET',
				data: question,
			});
			const q = QuestionService.parse(response.data);
			store.dispatch(startStopQuestion(q));
			await QuestionService.loadQuestion(survey, question.id);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}
	/**
	 * contains all surveydata (titel, creationdate, ...., answers)
	 * called in Hub => SurveyControl
	 * @param survey
	 */
	public static async exportSurvey(survey: ISurvey) {
		await download(`Survey/${survey.id}/Export`);
	}
}
