import React, { useState, useEffect } from 'react';
import styles from './ResultPersonDetailsPanel.module.scss';
import { Persona, PersonaSize } from 'office-ui-fabric-react';
import { IQuestion, IAnswerOption } from '../../store/business/interfaces';
import { useTranslation } from 'react-i18next';
import { formatFloat } from '../../shared/utils';
import { ExpandablePanel } from './ExpandablePanel';

export interface Props {
	answerOption: IAnswerOption;
	question: IQuestion;
	onDismiss: () => void;
}

const renderVoteValueAttendance = (question: IQuestion, answerOption: IAnswerOption, voteValue: string) => {
	const attendeesForVoteValue = answerOption.attendees.filter((a) => a.voteValue === voteValue);
	const attendeeIdsForVoteValue = attendeesForVoteValue.map((a) => a.id);
	const attendees = question.attendees.filter((a) => attendeeIdsForVoteValue.includes(a.id));
	return attendees.map((a) => {
		const voteWeight = attendeesForVoteValue.find((a4vv) => a4vv.id === a.id)?.voteWeight ?? 1;
		return (
			<li key={`a4vv-${a.id}-${voteValue}`} className={styles.memberItem}>
				<Persona
					text={a.text}
					secondaryText={voteWeight > 1 ? `Hat mit ${formatFloat(voteWeight)} Stimmen gewählt` : undefined}
					size={PersonaSize.size48}
				/>
			</li>
		);
	});
};

export const ResultPersonDetailsPanel = (props: Props) => {
	const { t } = useTranslation();
	const [personDetails, setPersonDetails] = useState<IAnswerOption | undefined>();

	useEffect(() => {
		setPersonDetails(props.answerOption);
	}, [props.answerOption]);

	if (!personDetails) {
		return <></>;
	}

	return (
		<ExpandablePanel isOpen={true} onDismiss={props.onDismiss} title='Beteiligung' subTitle={personDetails.title}>
			{personDetails && (
				<div className={styles.grid3}>
					{['yes', 'no', 'abstent'].map((voteValue) => {
						return (
							<ul key={`voteValue-${voteValue}`} className={styles.memberList}>
								<li className={styles.personDetailsVoteValue}>{t(`voting.person.${voteValue}`)}</li>
								{renderVoteValueAttendance(props.question, personDetails, voteValue)}
							</ul>
						);
					})}
				</div>
			)}
		</ExpandablePanel>
	);
};
