import styles from './EmptySurveyPanel.module.scss';

import { PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';

export interface Props {
	onCreateQuestion: () => void;
	onManageMembers: () => void;
	onGiveFeedback: () => void;
	createQuestionDisabled?: boolean;
	visible: boolean;
}

export const EmptySurveyPanel = (props: Props) => {
	if (!props.visible) {
		return <></>;
	} else {
		return (
			<div className={styles.emptyStartPanel}>
				<div className={styles.welcomeTitle}>Herzlich willkommen in VOTR!</div>
				<div className={styles.welcomeText}>Womit möchten Sie starten? Hier sind ein paar Vorschläge...</div>
				<div className={styles.actionBoxOuter}>
					<div className={styles.actionBoxContainer}>
						<div className={styles.actionBox}>
							<PrimaryButton
								text='Neue Abstimmung planen'
								disabled={props.createQuestionDisabled}
								onClick={() => {
									props.onCreateQuestion();
								}}
							/>
							<div className={styles.createQuestionImage + ' ' + styles.image} />
						</div>
						<div className={styles.actionBox}>
							<PrimaryButton
								text='Teilnehmer verwalten'
								onClick={() => {
									props.onManageMembers();
								}}
							/>
							<div className={styles.manageMembersImage + ' ' + styles.image} />
						</div>
						<div className={styles.actionBox}>
							<PrimaryButton
								text='Feedback geben'
								onClick={() => {
									props.onGiveFeedback();
								}}
							/>
							<div className={styles.giveFeedbackImage + ' ' + styles.image} />
						</div>
					</div>
				</div>
			</div>
		);
	}
};
