import React from 'react';
import { IconWithCallout } from '../../atoms/IconWithCallout';
import { QuestionFormToggle } from './QuestionFormToggle';

export interface EnableVotesSplittingToggleProps {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	onChanged: (checked: boolean) => void;
}

/**
 * if one member has more than 1 voteWeight, this determines if voteWeights can be split to separate ballots
 * @param props : {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	onChanged: (checked:boolean)=>void;
}
 * @returns QuestionFormToggle
 */
export const EnableVotesSplittingToggle = (props: EnableVotesSplittingToggleProps) => {
	return (
		<>
			{props.visible && (
				<>
					<QuestionFormToggle
						checkedLabelText='Stimmensplitting'
						uncheckedLabelText='kein Stimmensplitting'
						checked={props.checked}
						disabled={props.disabled}
						visible={props.visible}
						onChanged={props.onChanged}
						onRenderSuffix={() => (
							<IconWithCallout
								iconName='Info'
								renderCalloutContent={() => {
									return (
										<span>
											Mit aktiviertem Stimmensplitting sind die Teilnehmer dazu in der Lage, ihr Stimmengewicht auf die
											unterschiedlichen Antwortmöglichkeiten zu verteilen.
										</span>
									);
								}}
								iconContainerClassName='votrInfoIcon'
							/>
						)}
					/>
				</>
			)}
		</>
	);
};
