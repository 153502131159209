import React, { useState } from 'react';
import { TextQuestionForm } from '../organisms/QuestionForms/TextQuestionForm';
import { ChoiceQuestionForm } from '../organisms/QuestionForms/ChoiceQuestionForm';
import { PersonQuestionForm } from '../organisms/QuestionForms/PersonQuestionForm';
import { RatingQuestionForm } from '../organisms/QuestionForms/RatingQuestionForm';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { QuestionService } from '../../services/QuestionService';
import { IQuestion } from '../../store/business/interfaces';

export interface QuestionEditorProps {
	questionId: string;
	onClose: (wasCanceled: boolean) => void;
	onNotFound?: () => void;
}
/**
 * loads survey and question from redux store
 * calls QuestionService.upsertQuestion,
 * @param props : {
	questionId: string;
	onClose: (wasCanceled: boolean) => void;
	onNotFound?: () => void;
}
 * @returns QuestionForm, depending on kind of question, e.g. 'rating'
 */
export const QuestionEditor = (props: QuestionEditorProps) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const questions = useSelector((state: AppState) => state.business.questions);
	const [question, setQuestion] = useState<IQuestion>(questions.find((q) => q.id === props.questionId));

	if (!question || !survey) {
		props.onNotFound?.();
		return <></>;
	}

	return (
		<>
			{question.kind === 'choice' && (
				<ChoiceQuestionForm
					question={question}
					disabled={question.status !== 'unsaved' && question.status !== 'new'}
					setQuestion={setQuestion}
					onCancel={() => {
						props.onClose(true);
					}}
					onSave={(question) => {
						QuestionService.upsertQuestion(survey, question).then(() => {
							props.onClose(false);
						});
					}}
				/>
			)}
			{question.kind === 'person' && (
				<PersonQuestionForm
					question={question}
					disabled={question.status !== 'unsaved' && question.status !== 'new'}
					setQuestion={setQuestion}
					onCancel={() => {
						props.onClose(true);
					}}
					onSave={(question) => {
						QuestionService.upsertQuestion(survey, question).then(() => {
							props.onClose(false);
						});
					}}
				/>
			)}
			{question.kind === 'text' && (
				<TextQuestionForm
					question={question}
					disabled={question.status !== 'unsaved' && question.status !== 'new'}
					setQuestion={setQuestion}
					onCancel={() => {
						props.onClose(true);
					}}
					onSave={(question) => {
						QuestionService.upsertQuestion(survey, question).then(() => {
							props.onClose(false);
						});
					}}
				/>
			)}
			{
				question.kind === 'rating' && (
					<RatingQuestionForm
						question={question}
						disabled={question.status !== 'unsaved' && question.status !== 'new'}
						setQuestion={setQuestion}
						onCancel={() => {
							props.onClose(true);
						}}
						onSave={(question) => {
							QuestionService.upsertQuestion(survey, question).then(() => {
								props.onClose(false);
							});
						}}
					/>
				)
			}
		</>
	);
};
