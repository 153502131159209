import React from 'react';
import { IQuestion } from '../../store/business/interfaces';
import { ToggleableIcon } from '../../shared/components/atoms/ToggleableIcon';
import format from 'date-fns/format';

export interface Props {
	question: IQuestion;
}

export const QuestionStoppedIcon = (props: Props) => {
	const stopTime = props.question?.stopTime;
	const stopTimeText = stopTime ? `beendet am ${format(stopTime, 'dd.MM.yyyy HH:mm')} Uhr` : '';

	return <ToggleableIcon icons={[{ toggled:stopTime?.getTime() > 0, text:stopTimeText, iconName:'CirclePause'}]} />;
};
