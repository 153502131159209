import React, { useState, useEffect } from 'react';
import styles from './ExpandablePanel.module.scss';
import { CommandButton, Panel, PanelType } from 'office-ui-fabric-react';
import { renderCollapsedSvg, renderExpandedSvg } from '../atoms/svg';

export interface Props {
	children: React.ReactNode;
	isOpen: boolean;
	isExpanded?: boolean;
	title: string;
	subTitle: string | JSX.Element;
	onDismiss: () => void;
}

export const ExpandablePanel = (props: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	useEffect(() => {
		setIsExpanded(!!props.isExpanded);
	}, [props.isExpanded]);

	return (
		<Panel
			isOpen={props.isOpen}
			customWidth={isExpanded ? '100vw' : '66vw'}
			type={PanelType.custom}
			onDismiss={props.onDismiss}
		>
			<div className={styles.formContainer}>
				<header>
					<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
						<div className={styles.panelHeader}>
							<h1>{props.title}</h1>
						</div>
						<div className={[styles.flexContainer, styles.panelButtons].join(' ')}>
							<CommandButton
								onClick={() => {
									setIsExpanded((v) => !v);
								}}
							>
								{!isExpanded ? renderExpandedSvg() : renderCollapsedSvg()}
							</CommandButton>
							<CommandButton
								onClick={() => {
									props.onDismiss();
								}}
								iconProps={{ iconName: 'ChromeClose' }}
								className={styles.closeIcon}
							/>
						</div>
					</div>
					<div className={styles.meta}>
						<div className={[styles.topLeft, styles.subTitle].join(' ')}>{props.subTitle}</div>
					</div>
				</header>
				<main>{props.children}</main>
			</div>
		</Panel>
	);
};
