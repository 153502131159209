import React from 'react';
import { QuestionFormToggle } from './QuestionFormToggle';

import styles from './../Sidepanel.module.scss';
import { useTranslation } from 'react-i18next';

export interface Props {
	checked: boolean;
	disabled: boolean;
	visible: boolean;
	maxAnswerOptionCount: number;
	answerOptionCount: number;
	type: 'answers' | 'persons';
	onToggleChanged: (checked: boolean) => void;
	onMaxAnswerOptionCountChange: (maxAnswerOptionCount: number) => void;
}

export const LimitAnswerOptionsToggle = (props: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<QuestionFormToggle
				checkedLabelText={t(`limitAnswerOptionsToggle.${props.type}.checkedLabel`)}
				uncheckedLabelText={t(`limitAnswerOptionsToggle.${props.type}.uncheckedLabel`)}
				checked={props.checked}
				disabled={props.disabled}
				visible={props.visible}
				onChanged={props.onToggleChanged}
				onRenderSuffix={
					props.checked &&
					(() => (
						<div className={styles.multiVotesInputContainer}>
							<div className={'ms-TextField-fieldGroup'}>
								<input
									type='number'
									min='1'
									max={props.maxAnswerOptionCount}
									value={props.answerOptionCount}
									onChange={(evt) => props.onMaxAnswerOptionCountChange(parseInt(evt.currentTarget.value))}
									disabled={props.disabled}
									className={styles.numField}
								/>
							</div>
							<span className={styles.label}>{t(`limitAnswerOptionsToggle.${props.type}.entity`)}</span>
						</div>
					))
				}
			/>
		</>
	);
};
