import { IAnswerOption, IQuestion } from '../../../store/business/interfaces';

export const fixVotesPerMember = (propsQuestion: IQuestion, answerOptions: IAnswerOption[]): IQuestion => {
	let value = propsQuestion.votesPerMember;
	if (isNaN(value)) {
		value = 1;
	}
	if (value !== 1 && value > answerOptions.length && answerOptions.length > 0) {
		value = answerOptions.length;
	}
	if (propsQuestion.votesPerMember !== value) {
		return { ...propsQuestion, votesPerMember: value };
	} else {
		return propsQuestion;
	}
};
