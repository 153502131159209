import { FontIcon, Toggle } from 'office-ui-fabric-react';
import React from 'react';
import styles from './QuestionFormToggle.module.scss';

export interface Props {
	className?: string;
	uncheckedLabelText: string;
	uncheckedLabelIconName?: string;
	checkedLabelText: string;
	checkedLabelIconName?: string;
	checked: boolean;
	disabled: boolean;
	visible?: boolean;
	onChanged: (checked: boolean) => void;
	onRenderSuffix?: () => any;
}

export const QuestionFormToggle = (props: Props) => {
	const checkedLabelIcon = props.checkedLabelIconName ? <FontIcon iconName={props.checkedLabelIconName} /> : <></>;
	const uncheckedLabelIcon = props.uncheckedLabelIconName ? (
		<FontIcon iconName={props.uncheckedLabelIconName} />
	) : (
		<></>
	);

	return (
		<>
			{(props.visible === null || props.visible !== false) && (
				<div className={[styles.flexContainer, styles.toggleContainer, props.className].join(' ')}>
					<Toggle
						label={
							props.checked ? (
								<>
									{checkedLabelIcon}
									<span>{props.checkedLabelText}</span>
								</>
							) : (
								<>
									{uncheckedLabelIcon}
									<span>{props.uncheckedLabelText}</span>
								</>
							)
						}
						onChanged={props.onChanged}
						inlineLabel
						checked={props.checked}
						disabled={props.disabled}
					/>
					{props.onRenderSuffix && props.onRenderSuffix()}
				</div>
			)}
		</>
	);
};
