import * as React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react';

export interface IProps {
	margin: string;
}

export const InitialLoadingIndicator = (props: IProps) => {
	return (
		<>
			<ProgressIndicator label='Bitte warten' styles={{ root: { margin: props.margin } }} />
		</>
	);
}