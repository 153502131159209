import { Callout, DirectionalHint, Icon } from 'office-ui-fabric-react';
import React, { ReactNode, useState } from 'react';
import styles from './IconWithCallout.module.scss';



export interface Props {
	iconName: string;
	iconContainerClassName?: string;
	renderCalloutContent: () => ReactNode;
}

export const IconWithCallout = (props: Props) => {
	const [calloutIsVisible, setCalloutIsVisible] = useState(false);
	const [iconContainer, setIconContainer] = useState(null);

	return (
		<>
			<div className={[styles.iconContainer, props.iconContainerClassName].join(' ')} ref={div=>(setIconContainer(div))}>
				<Icon onClick={(e)=>{setCalloutIsVisible(!calloutIsVisible); e.stopPropagation(); }} iconName={props.iconName}></Icon>
			</div>
			{calloutIsVisible && (
				<Callout
					target={iconContainer}
					className={styles.callout}
					gapSpace={5}
					directionalHint={DirectionalHint.bottomCenter}
					onDismiss={()=>setCalloutIsVisible(false)}
				>
					{props.renderCalloutContent && props.renderCalloutContent()}
				</Callout>
			)}
		</>
	);
};
