import { IQuestion, IAnswerOption, ISurvey } from '../store/business/interfaces';
import { xhr, download } from '../xhr';
import { store } from '../store';
import { businessLoading, updateQuestion, removeQuestion } from '../store/business/actions';
import { BusinessDataType } from '../store/business/enums';
import { createGuid } from '../shared/utils';
import { MessageType, showMessage } from '../shared/notifications';

export class QuestionService {
	/**
	 * used to parse response-data from xhr-Requests into IQuestion-interface and answerOptions into IAnswerOption-interface
	 * @param data : any
	 * @returns question : IQuestion
	 */
	public static parse(data: any): IQuestion {
		const answerOptions = (data['answerOptions'] as []) || [];
		const votesPerMember = parseInt(data['votesPerMember']);
		const attendees = data['attendees'] || [];
		const title = data['title'];
		const startTime = data['startTime'] ? new Date(data['startTime']) : undefined;
		const stopTime = data['stopTime'] ? new Date(data['stopTime']) : undefined;
		const status = stopTime > new Date(0) ? 'stopped' : startTime > new Date(0) ? 'started' : 'new';
		let newAnswerObject : IQuestion = {
				id: data['id'],
				title,
				description: data['description'],
				kind: data['kind'],
				votesPerMember: isNaN(votesPerMember) ? 1 : votesPerMember,
				allowVotesSplitting: !!data['allowVotesSplitting'],
				answerOptions: answerOptions.map(
					(a: any) =>
						({
							id: a['id'],
							title: a['title'],
							votesCount: parseFloat(a['votes'] || 0),
							voteValues: a['voteValues'],
							attendees: a['attendees'] || [],
							type: 'answer',
							averageRating: a['averageRating']
						} as IAnswerOption)
				),
				security: data['security'],
				votesCount: attendees.length, // TODO: sum up vote count of attendees
				attendees,
				eTag: data['eTag'],
				startTime: startTime,
				stopTime: stopTime,
				status: status,
				type: 'question',
				sortOrder: title,
				loaded: new Date(),
				maxRating: data['maxRating'],
			}
			return newAnswerObject
	}

	public static parseArray(data: any[]): IQuestion[] {
		if (data && data.length) {
			return data.map(QuestionService.parse);
		}
		return [];
	}
	/**
	 * @returns creates default question
	 */
	public static emptyQuestion(): IQuestion {
		return {
			id: '',
			title: '',
			description: '',
			votesPerMember: 1,
			allowVotesSplitting: false,
			kind: 'choice',
			security: 'public',
			answerOptions: [],
			votesCount: 0,
			attendees: [],
			status: 'unsaved',
			eTag: '*',
			startTime: undefined,
			stopTime: undefined,
			type: 'question',
			loaded: new Date(),
			maxRating: 0,
		};
	}
	/**
	 * creates an empty Question with a unique ID
	 * stores Question in Redux Store
	 * @returns question : IQuestion
	 */
	public static createQuestion() {
		const question = QuestionService.emptyQuestion();
		question.id = createGuid();
		store.dispatch(updateQuestion(question));
		return question;
	}

	public static removeQuestion(question: IQuestion) {
		store.dispatch(removeQuestion(question));
	}

	/**
	 * loads current state from redux store, checking for existence
	 * sends xhr POST request
	 * stores updated Question in redux store
	 * calls QuestionService.parse
	 * @param survey context of the question, needed for identification
	 * @param question the question-data that is sent to Database
	 * @returns Promise<void>
	 */
	public static async upsertQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (!survey || !question || business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question`, {
				method: 'POST',
				data: question,
			});
			const q = QuestionService.parse(response.data);
			store.dispatch(updateQuestion(q));
		} catch (e) {
			showMessage(`Fehler beim Speichern der Abstimmung!`, MessageType.ERROR);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}

	/**
	 * gets state from redux store,
	 * sends xhr GET-request to Backend to get specific question
	 * dispatches updated result to redux store
	 * @param survey context of the question, needed for identification
	 * @param id question Id, needed to retrieve question from database
	 * @returns Promise<void>
	 */
	public static async loadQuestion(survey: ISurvey, id?: string) {
		const { business } = store.getState();
		if (!survey || !id || business.loading[BusinessDataType.Question]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Question, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question/${id}`, {
				method: 'GET',
			});
			const q = QuestionService.parse(response.data);
			q.loaded = new Date();
			store.dispatch(updateQuestion(q));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Question, false));
		}
	}

	/**
	 * exports information of Vote ans results into csv-file 
	 * @param survey : ISurvey
	 * @param question : IQuestion
	 */
	public static async exportQuestion(survey: ISurvey, question: IQuestion) {
		if (!survey || !question) return;
		await download(`Survey/${survey.id}/Question/${question.id}/Export`);
	}

	/**
	 * gets state from redux store,
	 * sends xhr PATCH-request to Backend to update specific question
	 * dispatches updated result to redux store
	 * @param survey context of the question, needed for identification
	 * @param question question Id, needed to retrieve question from database
	 * @returns Promise<void>
	 */
	public static async resetQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (!survey || !question || business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			const response = await xhr(`Survey/${survey.id}/Question/${question.id}/Reset`, {
				method: 'PATCH',
			});
			const q = QuestionService.parse(response.data);
			q.votesCount = 0;
			store.dispatch(updateQuestion(q));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}

	/**
	 * gets state from redux store,
	 * sends xhr DELETE-request to Backend to update specific question
	 * dispatches updated result to redux store
	 * @param survey
	 * @param question
	 * @returns Promise<void>
	 */
	public static async deleteQuestion(survey: ISurvey, question: IQuestion) {
		const { business } = store.getState();
		if (!survey || !question || business.loading[BusinessDataType.Questions]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Questions, true));
		try {
			await xhr(`Survey/${survey.id}/Question/${question.id}`, {
				method: 'DELETE',
			});
			store.dispatch(removeQuestion(question));
			// showMessage(`Abstimmung erfolgreich gelöscht`, MessageType.SUCCESS);
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Questions, false));
		}
	}
}
