import { CommandButton } from 'office-ui-fabric-react';
import { IconWithCallout } from './IconWithCallout';
import React, { useState } from 'react';
import { IQuestion } from '../../store/business/interfaces';
import styles from './QuestionTitle.module.scss';

export interface Props {
	question: IQuestion;
}

export const QuestionTitle = (props: Props) => {
	const [calloutKey, updateCalloutKey] = useState(0);

	if (!props.question) {
		return <></>;
	}

	const description = props.question.description;

	return (
		<div className={styles.titleContainer}>
			<span title={props.question.title} className={styles.title}>
				{props.question.title}
			</span>
			{description && (
				<IconWithCallout
					key={calloutKey}
					iconContainerClassName={[styles.infoButton, "votrInfoIcon"].join(' ')}
					iconName='Info'
					renderCalloutContent={() => {
						return (
							<>
								<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
									<div className={styles.panelHeader}>Beschreibung</div>
									<div>
										<CommandButton
											onClick={() => updateCalloutKey((i) => i + 1)}
											iconProps={{ iconName: 'ChromeClose' }}
										/>
									</div>
								</div>
								<div className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></div>
							</>
						);
					}}
				/>
			)}
		</div>
	);
};
